<template>
    <a-layout>
        <a-layout-header class="header-class" >
            <div style="float: left">
                <img class="logo-class" :src="system_logo" v-if="system_logo" alt="">
                <img class="logo-class" src="../assets/logo.png" v-else alt="">
                <div class="project-name-class">{{system_name}}</div>
            </div>

            <div style="float: right;color:white;margin-right: 20px">
                <el-dropdown>
                    <span><a href="javascript:;" style="cursor:pointer;">{{ admin.name }}</a></span>
                    <el-dropdown-menu slot="dropdown" >
                        <el-dropdown-item @click.native="changePassword" v-if="admin.id > 0">修改密码</el-dropdown-item>
                        <el-dropdown-item  @click.native="logout" >
                            <i class="el-icon-setting"> 退 出 </i>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

                <!--修改密码-->
                <a-drawer
                        title="修改密码"
                        :width="360"
                        :visible="changePwd"
                        placement="left"
                        :body-style="{ paddingBottom: '80px' }"
                        @close="cancelChangePwd()">
                    <a-form layout="vertical" hide-required-mark>
                        <a-form-item label="原密码" >
                            <a-input
                                    size="large"
                                    v-model:value="pwdData.password"
                                    allow-clear

                            />
                        </a-form-item>
                        <a-form-item label="新密码" >
                            <a-input-password
                                    size="large"
                                    v-model:value="pwdData.newPassword"
                                    allow-clear

                            />
                        </a-form-item>
                        <a-form-item label="重复新密码" >
                            <a-input-password
                                    size="large"
                                    v-model:value="pwdData.rePassword"
                                    allow-clear

                            />
                        </a-form-item>
                    </a-form>
                    <div >
                        <a-button type="info" :style="{ marginRight: '40px',marginLeft:'120px' }" @click="cancelChangePwd()">
                            取消
                        </a-button>
                        <a-button type="primary" :style="{ marginRight: '8px' }" @click="changePwdSubmit()">
                            提交
                        </a-button>
                    </div>
                </a-drawer>
                <!--修改密码-->


            </div>

        </a-layout-header>
        <a-layout class="sider-content-class">
            <a-layout-sider  v-model:collapsed="collapsed"  width="230">
                <a-menu class="menu-class" theme="dark"  v-model:selectedKeys="selectKeys" mode="inline" :open-keys.sync="openKeys" >
                    <template v-for="item in menuData">
                        <a-menu-item v-if="!item.hasOwnProperty('children')" :key="item.value">
                            <a-icon v-if="item.icon" :type="item.icon" />
                            <span>{{ item.label }}</span>
                        </a-menu-item>
                        <a-sub-menu v-else :key="item.value" >
                            <template slot="title"> <a-icon v-if="item.icon" :type="item.icon" /><span>{{item.label}}</span> </template>
                            <template v-for="subItem in item.children" >
                                <a-menu-item v-if="!subItem.hasOwnProperty('children')" :key="subItem.value" >
                                    {{ subItem.label }}
                                </a-menu-item>
                                <a-sub-menu v-else :key="subItem.value" :title="subItem.label">
                                    <a-menu-item v-for="sub in subItem.children" :key="sub.value">
                                        {{ sub.label }}
                                    </a-menu-item>
                                </a-sub-menu>
                            </template>
                        </a-sub-menu>
                    </template>
                </a-menu>
            </a-layout-sider>
            <a-layout>
                <a-layout-content class="content-class" >
                    <a-tabs v-model:activeKey="activeKey" hide-add type="editable-card" @edit="onEdit">
                        <a-tab-pane class="tab-pane-class" :closable="false" :key="-1" tab="首页">
                            <component is="LuckyIndex"></component>
                        </a-tab-pane>
                        <a-tab-pane v-for="pane in panes" :key="pane.value" class="tab-pane-class" :tab="pane.label">
                            <component is="LuckyLayout" :paneData="pane"></component>
                        </a-tab-pane>
                    </a-tabs>
                </a-layout-content>
                <a-layout-footer style="text-align: center;">
                    <div style="float:left;font-size: 24px"><a-icon :type="collapsIcon" @click="collapse"/></div>
<!--                    LuckyAdmin Created by RiverGod 3.2-->
                </a-layout-footer>
            </a-layout>
        </a-layout>
    </a-layout>


</template>
<script>


    import router from '../router';
    export default {
        data() {
            return {
                system_logo:'logo.png',                  //项目LOGO
                system_name:'Lucky3.2',                  //项目名称
                admin:{},                                //项目名称

                changePwd:false,                                      //修改密码抽屉状态
                pwdData:{password:'',newPassword:'',rePassword:''},   //修改密码数据

                collapsed: false,            //是否缩起侧边栏
                collapsIcon: 'menu-fold',    //操作侧边栏的图标
                selectKeys: [],              //已选中的菜单
                menuData:{},                 //菜单数据
                rootSubmenuKeys:[],          //根菜单
                openKeys:[],                 //展开的菜单

                activeKey: -1,                //当前pane
                panes:[],                     //已打开pane
                panesKey:[],                  //已打开pane的key
                tempPaneData:[]               //打开pane临时数据
            };
        },
        created() {
            let that = this
            //登陆后初始化数据
            that.$ajax.post('/auth/Admin/afterLogin', {})
                .then(res=>{
                    document.title = res.system_name?res.system_name:'Lucky3.2';
                    that.menuData = res.menu;
                    that.system_name = res.system_name?res.system_name:'Lucky3.2';
                    that.system_logo = res.system_logo;
                    that.admin = res.admin;
                    //获取根菜单
                    let i = 0;
                    res.menu.forEach((items)=>{
                        if(items.hasOwnProperty('children')){
                            this.rootSubmenuKeys[i] = items.value;
                            i++;
                        }
                    })

                })
        },
        methods: {
            //收起展开菜单栏
            collapse(){
                if(this.collapsed){
                    this.collapsIcon = 'menu-fold';
                }else {
                    this.collapsIcon = 'menu-unfold';
                }
                this.collapsed = !this.collapsed
            },
            //只展开一组菜单
            onOpenChange(openKeys) {
                const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
                if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                    this.openKeys = openKeys;
                } else {
                    this.openKeys = latestOpenKey ? [latestOpenKey] : [];
                }
            },
            //移除pane
            onEdit(targetKey, action) {
                this[action](targetKey);
            },
            remove(targetKey) {
                this.panes.forEach((item,index)=>{
                    if(item.value == targetKey){
                        this.panes.splice(index,1)
                        this.panesKey.splice(this.panesKey.indexOf(targetKey),1)
                    }
                })
                //切换pane和重新选中菜单
                if(this.panes.length > 0){
                   this.selectKeys[0] = this.activeKey = this.panes[this.panes.length - 1].value
                }else {
                    this.selectKeys[0] = this.activeKey = -1;
                }
                // this.onOpenChange(this.selectKeys)
            },
            //切换pane---递归菜单
            findChildrMenu(item,value){
                if(item.hasOwnProperty('children')){
                    item.children.forEach((i)=>{
                        if(i.value == value){
                            this.tempPaneData = i
                        }
                        this.findChildrMenu(i,value)
                    })
                }
            },
            //打开修改密码抽屉
            changePassword(){
                if(this.changePwd)
                    this.changePwd = false
                else
                    this.changePwd = true
            },
            //提交修改密码
            changePwdSubmit() {
                if(!this.pwdData.password || !this.pwdData.newPassword || !this.pwdData.rePassword)
                    return false
                if(this.pwdData.newPassword != this.pwdData.rePassword){
                    this.$message.error({message:"两次输入不一致，请重新输入",showClose: true});
                    return
                }
                this.$ajax.post('/auth/Admin/changePassword',this.pwdData).then(res=>{
                    this.$message.success({message:res.message,showClose: true});
                    this.cancelChangePwd()
                })
            },
            //取消修改密码
            cancelChangePwd() {
                this.pwdData.password = ''
                this.pwdData.newPassword = ''
                this.pwdData.rePassword = ''
                this.changePwd = false;
            },
            //注销
            logout(){
                this.$messageBox.confirm('确定注销当前登陆？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    localStorage.setItem('Authorization', '');
                    router.push('/')
                }).catch(action => {
                });
            },
        },
        watch:{
            //切换pane
            selectKeys(value){
                //已打开pane,切换过去
                if(this.panesKey.indexOf(value[0]) === -1){
                    //未打开
                    this.menuData.forEach((items)=>{
                        if(items.value == value[0]){
                            this.tempPaneData = items
                        }
                        this.findChildrMenu(items,value[0])
                    })
                    this.panes.push(this.tempPaneData)
                    this.panesKey.push(this.tempPaneData.value)
                }
                this.activeKey = value[0];

            }
        }
    };
</script>

<style lang="less">
    .header-class{
        background: #001529;
        line-height: 44px;
        padding: 7px 15px;
        .logo-class{
            width: auto;
            height: 50px;
            border-radius: 4px;
            float: left
        };
        .project-name-class{
            float: left;
            margin-left: 10px;
            color: white;
            font-size: 30px;
        }
    }
    .sider-content-class{
        height: calc(100vh - 64px);
        .menu-class{
            height: calc(100vh - 64px);
            padding-bottom: 20px;
            overflow-y: auto;
        }
        .content-class{
            background-color: white;
            height: calc(100vh - 132px);
            padding: 16px 16px 0;
            .ant-tabs-bar{
                margin: 0 !important;
            }
        }
        .ant-layout-footer{
            padding: 7px 50px 7px 20px;
            line-height: 36px;
        }
    }
    .tab-pane-class{
        border: 1px solid #f0f0f0;
        border-top: none;
        height: calc(100vh - 180px);
        /*overflow: auto;*/
        border-radius: 0 0 2px 2px;
        padding: 5px 10px;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
        border-radius: 4px 4px 0 0;
    }
    .ant-form input[type='file']{
        display: none !important;
    }
    .warning-class{
        color: red;
        display: block;
        min-height: 10px;
        /*line-height: 30px;*/
        margin-bottom: 10px;
    }

    .el-table thead {
        color: rgb(52, 47, 47) !important;
        letter-spacing: 1px
    }

    .el-table th > .cell {
        font-weight: 500 !important;
    }

    .el-table__body-wrapper {
        overflow-y: auto;
    }
</style>
